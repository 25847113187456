import * as utilCommon from 'helpers/util-common';
import { minDateForInputDateFields, OfficerTitleEnum } from 'models/common.models';
import moment from 'moment';
import { find, forEach, isEmpty, uniq } from 'lodash';
import { DropdownFormatting, getMessage } from '../../../../../helpers/util-common';
import { primaryActivityModelConstant } from '../../../../admin/manual-order/manual-order.model';

let self = {};
let accountNo = '';
let updatedDetailsForupdateMemberCommitteeBasicDetails = {};
let isFacility = false;
export const setClassInstance = (instance) => {
    self = instance;
    accountNo = self.props.initialValues.AccountNumber;
    isFacility = self.props.initialValues.FacilityName;
    if (isFacility) {
        self.setState({ isOrgAssociated: true });
    }
    // Get Membership type Id.
    let memberTypeId = self.props.initialValues.MemberTypeId;
    // If Membership type is REP.
    if (memberTypeId == 14) {
        memberTypeId = 3;
    }
    getPrimaryActivityList(memberTypeId || 0);
}

export const loadInitialData = () => {
    let committeeDesignationNameList = [];
    let noVoteReasons = [];
    self.props.getAddMemberCommitteeMasterData(accountNo, () => {
        setTimeout(() => {
            committeeDesignationNameList = formatCommitteeDesignationList(self.props.committeeList);
        if (self.props.noVoteReasonList && self.props.noVoteReasonList.length > 0) {
            self.props.noVoteReasonList.map(item => noVoteReasons.push({ key: item.VoteId, text: item.Description, value: item.VoteId }))
        }
        self.setState({ committeeDesignationNameList, noVoteReasons, selectedVote: 1 })
        }, 1000);
    })
}
export const formatCommitteeDesignationList = (list) => {
       if (list && list.length > 0) {
        let result = list
          .filter((item) => (self.props.initialValues?.MemberTypeId === 17 && item.IsStudentEnabled && self.state.flagFeature?.StudentCommitteeManagement) || (self.props.initialValues?.MemberTypeId !== 17 || !self.state.flagFeature?.StudentCommitteeManagement))
          .map((res) => {
            res.key = res.CommitteeId;
            res.text = `${res.Title}`;
            res.value = `${res.CommitteeId}`;
            return res;
        });
        return result;
    }

    return [];
}

export const changeVoteStatusHandler = (e, key) => {
    self.setState({ selectedVote: key });
}

export const onChangeVoteStatus = (e) => {
    let selectedVoteStatus = e.currentTarget.querySelector('input').value;
    self.setState({ selectedVoteStatus });
}

export const loadDependentList = () => {
    if (self.state.selectedCommittees.length > 0) {

        self.props.getAddMemberCommitteeSecondryMasterData(self.state.selectedCommittees, (classificationResponse, officerTitleResponse) => {
            let commonClassification = [];
            commonClassification.push({ key: 5, text: 'Unclassified', value: 5 });
            if (classificationResponse && classificationResponse.length > 0) {
                commonClassification = [];
                classificationResponse.map(item => commonClassification.push({ key: item.ClassificationTypeId, text: item.Name, value: item.ClassificationTypeId }))
                self.setState({ commonClassification, selectedVote: 1 }, () => {
                    if (commonClassification.filter(item => item.text.toLowerCase() === 'unclassified').length > 0) {
                        let defaultCommitteeId = commonClassification.filter(item => item.text.toLowerCase() === 'unclassified')[0].value;
                        self.props.change('classificationTypeId', defaultCommitteeId)
                    } else {
                        self.props.change('classificationTypeId', 5)
                    }

                })
            }
            else {
                commonClassification = [];
                commonClassification.push({ key: 5, text: 'Unclassified', value: 5 });
                let commonOfficerTitle = [];
                self.setState({ commonOfficerTitle, commonClassification })
            }
            let commonOfficerTitle = [];
            let tempCommonOfficerTitle = [];
            if (officerTitleResponse) {
                self.props.getMemberOfficerTitle(officerTitleResponse.split(','), (officerResponse) => {
                    if (officerResponse && officerResponse.length > 0) {
                        officerResponse.map(item => tempCommonOfficerTitle.push({ key: item.OfficerTitleId, text: item.OfficerTitleName, value: item.OfficerTitleId }))
                    }
                    tempCommonOfficerTitle = tempCommonOfficerTitle.sort((a, b) => (b.text.toLowerCase() - a.text.toLowerCase()) ? 1 : -1);
                    if (tempCommonOfficerTitle && tempCommonOfficerTitle.length > 0) {
                        tempCommonOfficerTitle.map(item => commonOfficerTitle.push(item))

                    }
                    commonOfficerTitle.concat(tempCommonOfficerTitle)
                    self.setState({ commonOfficerTitle: sortCommonOfficerTitles(commonOfficerTitle) })
                })
            }


        })
    } else {
        let commonClassification = [];
        commonClassification.push({ key: 5, text: 'Unclassified', value: 5 });
        let commonOfficerTitle = [];
        self.setState({ commonOfficerTitle, commonClassification })
    }

}
export const isValidDate = (value, formatType) => {
    if (value !== "") {
        let sptArr = value.split('/');
        if (sptArr !== null && sptArr.length === 3) {
            if ((sptArr[2] === '0000' && formatType === 1) || (sptArr[1] === '0000' && formatType === 2)) {
                return false;
            }
        }

    }
    return moment(value, formatType === 1 ? 'MM/DD/YYYY' : 'MM/YYYY', true).isValid();
}

export const confirmAdministrativeAssistantOrStaffManagerPopup = (event, value) => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });

    // Updating Member Committee Basic Details
    saveMemberCommittee(updatedDetailsForupdateMemberCommitteeBasicDetails);
}
export const checkCommitteeOfficer = (value) => {
    let committeeArray = [];
    let subCommitteeArray = [];
    const committeeIds = [];
    const subCommitteeIds = [];
    let committeeDetails = null;
    const { AccountNumber, initialValues: { CommitteeDesignation }, memberCommitteeInfo } = self.props
    const { selectedCommittees, MemberId, committeeDesignationNameList, isPrimaryActivityApplicable } = self.state
    selectedCommittees.forEach(item => {
        let comm = committeeDesignationNameList.filter(x => x.CommitteeId == item)[0].Title;
        let committeeItems = committeeDesignationNameList.filter(x => x.CommitteeId == item)[0];
        if (comm.indexOf('.') > -1) {
            subCommitteeArray.push(comm);
            committeeArray.push(comm.split('.')[0]);
            subCommitteeIds.push(committeeItems.CommitteeId);
        }
        else {
            committeeIds.push(committeeItems.CommitteeId);
        }
    });
    value.CommitteeIds = committeeIds;
    value.subCommitteeIds = subCommitteeIds;
    if (isPrimaryActivityApplicable) {
        committeeDetails = committeeAndClassificationDetails(value);
    }
    updatedDetailsForupdateMemberCommitteeBasicDetails = { ...value, committeeDetails };
    if ((self.state.officerTitleName == OfficerTitleEnum.StaffManager || self.state.officerTitleName == OfficerTitleEnum.AdministrativeAssistant) && subCommitteeArray.length > 0) {
        self.setState({
            isShowAdministrativeAssistantOrStaffManagerPopup: true,
            committeeName: [...new Set(committeeArray.map(bill => bill))].join(', ')
        });
    }
    else {
        self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
        saveMemberCommittee(updatedDetailsForupdateMemberCommitteeBasicDetails);
    }
}

export const saveMemberCommittee = (value) => {
    let postdata = {
        AccountNumber: accountNo,
        CommitteeDesignation: self.state.selectedCommittees,
        Activity: 1,
        DateJoined: value.dateJoined,
        ClassificationTypeId: value.classificationTypeId,
        Vote: value.voteStatus ? value.voteStatus : false,
        NoVoteReason: (!value.voteStatus || value.voteStatus == 'false') ? value.noVoteReason : 0,
        VoteDate: value.voteDate,
        OfficerTitleId: value.officerTitle < 0 ? 0 : value.officerTitle,
        OfficerAppointedDate: value.officerAppointedDate,
        RosterNotes: value.rosterNotes,
        ClassificationDate: value.ClassificationDate,
        officerTitleName: self.state.officerTitleName,
        UpdateReason: value.UpdateReason,
        MemberTypeId: self.props.initialValues ? self.props.initialValues.MemberTypeId : 0,
        CommitteeDetails: value.committeeDetails
    }
    self.props.addMemberCommittee(postdata, (response) => {
        if (response !== undefined && response.message === 3025) {
            self.setState({
                isShowAdministrativeAssistantPopup: true,
                committeeDesignationValidation: response.content
            });
        }
        else {
            self.props.getMemberDetails(accountNo);
            self.props.close();
            self.props.refreshList();
        }
    });
    self.props.isGridEdit(false);
}

export const handleClickOk = (e) => {
    self.setState({ isShowAdministrativeAssistantPopup: false, committeeDesignationValidation: '' });
}

export const validateHandler = (values, props) => {
    const errors = {};
    if (!values.çommitteeDesignation || !utilCommon.Blank_Space_Check_Regex.test(values.çommitteeDesignation)) { // Validation for title
        errors.çommitteeDesignation = utilCommon.getMessage(props.messageCodes, '8129.text');
    }
    if (!values.classificationTypeId || values.classificationTypeId <= 0) { // Validation for title
        errors.classificationTypeId = utilCommon.getMessage(props.messageCodes, '8126.text');
    }

    if (!values.dateJoined) { // Validation for Joined date
        errors.dateJoined = utilCommon.getMessage(props.messageCodes, '3076.text');
    } else if (!isValidDate(values.dateJoined, 1)) {
        errors.dateJoined = utilCommon.getMessage(props.messageCodes, '3077.text');
    } else if (new Date(values.dateJoined) > new Date()) {
        errors.dateJoined = utilCommon.getMessage(props.messageCodes, '3077.text');
    } else if (utilCommon.compareDates(minDateForInputDateFields, values.dateJoined)) {
        errors.dateJoined = utilCommon.getMessage(props.messageCodes, '4021.text');
    }

    if (values.officerTitle > 0 && !values.officerAppointedDate) {
        errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '3078.text');
    } else if (values.officerTitle > 0 && (values.officerAppointedDate.length < 7 || !utilCommon.checkIfDateValid(values.officerAppointedDate, 'MM/YYYY'))) {
        errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '3079.text');
    } else if (values.officerTitle > 0 && values.officerAppointedDate) {
        let year = Number(values.officerAppointedDate.substring(3));
        if (isNaN(year) || (year <= 0)) {
            errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '4003.text');
        } else if (utilCommon.compareDates(values.officerAppointedDate, new Date(), true, 'MM/YYYY')) {
            errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '3084.text');
        } else if (utilCommon.compareDates(utilCommon.formatDateToMMYYYY(minDateForInputDateFields), values.officerAppointedDate, true, 'MM/YYYY')) {
            errors.officerAppointedDate = utilCommon.getMessage(props.messageCodes, '4021.text');
        }
    }
    if (!values.voteDate) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3080.text');
    } else if (values.voteDate && values.dateJoined && utilCommon.compareDates(values.dateJoined, values.voteDate, true)) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3114.text');
    }
    if (!values.UpdateReason) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text')
    }

    if (!values.voteStatus && (!values.noVoteReason || values.noVoteReason <= 0)) {
        errors.noVoteReason = utilCommon.getMessage(props.messageCodes, '8127.text');
    } else if ((values.voteStatus || values.voteStatus == 'true') && !values.voteDate) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3080.text');
    } else if ((values.voteStatus || values.voteStatus == 'true') && !isValidDate(values.voteDate, 1)) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3081.text');
    } else if ((values.voteStatus || values.voteStatus == 'true') && isValidDate(values.voteDate, 1) && new Date(values.voteDate) > new Date()) {
        errors.voteDate = utilCommon.getMessage(props.messageCodes, '3081.text');
    }
    if (!values.ClassificationDate) {
        errors.ClassificationDate = utilCommon.getMessage(props.messageCodes, '3115.text');
    } else if (values.ClassificationDate && values.dateJoined && utilCommon.compareDates(values.dateJoined, values.ClassificationDate, true)) {
        errors.ClassificationDate = utilCommon.getMessage(props.messageCodes, '3113.text');
    }
    // Primary Activity Errors Fields.
    if (values.primaryActivityOfMember < 0) {
        errors.primaryActivityOfMember = getMessage(props.messageCodes, '9112.text');
    }
    if (!values.describeProducts || (values.describeProducts && values.describeProducts.trim().length === 0)) {
        errors.describeProducts = getMessage(props.messageCodes, '9114.text');
    }

    // Validation for Consultant Details Form
    if (!values.consultingFirmName || (values.consultingFirmName && values.consultingFirmName.trim().length === 0)) {
        errors.consultingFirmName = getMessage(props.messageCodes, '9115.text');
    }
    if (!values.organizationNameYouRepresent || (values.organizationNameYouRepresent && values.organizationNameYouRepresent.trim().length === 0)) {
        errors.organizationNameYouRepresent = getMessage(props.messageCodes, '9528.text');
    }
    if (!values.bestDescribesConsultingFirm || (values.bestDescribesConsultingFirm && values.bestDescribesConsultingFirm.trim().length === 0)) {
        errors.bestDescribesConsultingFirm = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.organizationNameConsultantDetails || (values.organizationNameConsultantDetails && values.organizationNameConsultantDetails.trim().length === 0)) {
        errors.organizationNameConsultantDetails = getMessage(props.messageCodes, '9113.text');
    }
    if (!values.organizationPrimarilyProduceProducts || (values.organizationPrimarilyProduceProducts && values.organizationPrimarilyProduceProducts.trim().length === 0)) {
        errors.organizationPrimarilyProduceProducts = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.productSells || (values.productSells && values.productSells.trim().length === 0)) {
        errors.productSells = getMessage(props.messageCodes, '9120.text');
    }
    if (!values.businessActivity || (values.businessActivity && values.businessActivity.trim().length === 0)) {
        errors.businessActivity = getMessage(props.messageCodes, '9121.text');
    }
    if (!values.anyOrganizationPrimarilyProduceProducts || (values.anyOrganizationPrimarilyProduceProducts && values.anyOrganizationPrimarilyProduceProducts.trim().length === 0)) {
        errors.anyOrganizationPrimarilyProduceProducts = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.organizationNameYouRepresenting || (values.organizationNameYouRepresenting && values.organizationNameYouRepresenting.trim().length === 0)) {
        errors.organizationNameYouRepresenting = getMessage(props.messageCodes, '9113.text');
    }

    if (!values.natureOfInterest || (values.natureOfInterest && values.natureOfInterest.trim().length === 0)) {
        errors.natureOfInterest = getMessage(props.messageCodes, '9124.text');
    }
    // Validation for Consumer Details form
    if (!values.consumerAdvocacyGroupBelong || (values.consumerAdvocacyGroupBelong && values.consumerAdvocacyGroupBelong.trim().length === 0)) {
        errors.consumerAdvocacyGroupBelong = getMessage(props.messageCodes, '9017.text');
    }
    if (!values.consumerAdvocacyGroupName || (values.consumerAdvocacyGroupName && values.consumerAdvocacyGroupName.trim().length === 0)) {
        errors.consumerAdvocacyGroupName = getMessage(props.messageCodes, '9126.text');
    }
    if (!values.natureOfInterestConsumerDetails || (values.natureOfInterestConsumerDetails && values.natureOfInterestConsumerDetails.trim().length === 0)) {
        errors.natureOfInterestConsumerDetails = getMessage(props.messageCodes, '9124.text');
    }
    return errors;
}

export const isEnablePrimaryActivity = (list) => {
    const data = self.state.committeeDesignationNameList.filter(x => list.indexOf(String(x.CommitteeId)) > -1);
    let isPrimayActivityEnabled = false;
    data.forEach(element => {
        if (element && element.Title.indexOf('.') === -1) {
            isPrimayActivityEnabled = true;
            return false;
        };
    });
    return isPrimayActivityEnabled;
}

export const handleCommitteeChange = (event, data) => {

    let selectedCommittees = [];
    let previousSelectedCommittees = [];
    let selectedcommitteesEstablishmentDates = [];
    selectedCommittees = data
    previousSelectedCommittees = self.state.selectedCommittees;
    let itemDifferent = previousSelectedCommittees.filter((item) => !selectedCommittees.some((item2) => item2 == item))
    if (itemDifferent && itemDifferent.length > 0) {
        let itemRemoved = [];
        let currentItem = [];
        do {
            itemDifferent = (currentItem && currentItem.length > 0) ? currentItem : itemDifferent;
            currentItem = [];
            if (self.state.committeeDesignationNameList.filter(item => itemDifferent.some(item1 => item1 == item.ParentCommitteeId)).length > 0) {

                self.state.committeeDesignationNameList.map((item) => {
                    if (itemDifferent.some(item1 => item1 == item.ParentCommitteeId)) {
                        currentItem.push(item.CommitteeId);
                        itemRemoved.push(item.CommitteeId);
                    }
                })


            }
        }
        while (currentItem && currentItem.length > 0);

        if (itemRemoved && itemRemoved.length > 0) {
            itemRemoved = [...new Set(itemRemoved)]
            selectedCommittees = selectedCommittees.filter(item => !itemRemoved.some(item1 => item1 == item))
        }
    } else {
        let itemAdded = []
        selectedCommittees.map(item => self.state.committeeDesignationNameList.filter(item2 => item2.CommitteeId == item)[0].ParentCommittees.split(',').filter(item => { if (self.state.committeeDesignationNameList.some(item2 => item2.CommitteeId == item)) itemAdded.push(item.toString()) }))

        itemAdded = [...new Set(itemAdded)]
        if (itemAdded && itemAdded.length > 0) {
            selectedCommittees = itemAdded;
        }
    }

    self.state.committeeDesignationNameList.forEach(committeeListItem => {
        selectedCommittees.filter((selectedCommitteeItem) => {
            if (committeeListItem.CommitteeId === JSON.parse(selectedCommitteeItem)) {
                selectedcommitteesEstablishmentDates.push(utilCommon.formatDateToMMDDYYYY(committeeListItem.EstablishmentDate))
            }
        })
    })
    selectedcommitteesEstablishmentDates.sort((a, b) => { return new Date(b) - new Date(a) })
    let minDate = selectedcommitteesEstablishmentDates[0] ? selectedcommitteesEstablishmentDates[0] : utilCommon.formatDateToMMDDYYYY(minDateForInputDateFields)
    self.setState({ minDate: utilCommon.formatDateToMMDDYYYY(minDate) })

    if (utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(minDate), utilCommon.formatDateToMMDDYYYY(self.state.dateJoined), false, 'MM/DD/YYYY')) {
        self.props.change('dateJoined', utilCommon.formatDateToMMDDYYYY(minDate))
        self.setState({ dateJoined: utilCommon.formatDateToMMDDYYYY(minDate) })
    }
    if (!selectedcommitteesEstablishmentDates[0]) {
        self.props.change('dateJoined', utilCommon.formatDateToMMDDYYYY())
        self.setState({ dateJoined: utilCommon.formatDateToMMDDYYYY() })
    }
    setTimeout(() => {
        self.props.change('çommitteeDesignation', selectedCommittees)
    }, 100);
    const isVisiblePrimaryActivity = isEnablePrimaryActivity(selectedCommittees);
    self.setState({ selectedCommittees, isPrimaryActivityApplicable: isVisiblePrimaryActivity }, () => {
        loadDependentList()
        const { isPrimaryActivityApplicable } = self.state;
        if (isPrimaryActivityApplicable) {
            if (self.props.initialValues?.MemberTypeId === 17 && self.state.flagFeature?.StudentCommitteeManagement) {
              self.props.change("primaryActivityOfMember", 10);
              self.setState({ primaryActivityOfMember: 10 });
              self.props.change("natureOfInterest", "Student Member participation in committee");
            } else {
              self.props.change("primaryActivityOfMember", -1);
              self.setState({ primaryActivityOfMember: -1 });
            }
        }
    });
}
export const handleChangeDateJoined = (event, value) => {
    self.setState({ dateJoined: utilCommon.formatDateToMMDDYYYY(value) })

    if (utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(value), utilCommon.formatDateToMMDDYYYY(self.state.classificationDate), true, 'MM/DD/YYYY')) {
        self.props.change('ClassificationDate', value);
        self.setState({ classificationDate: value })
    }
    if (utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(value), utilCommon.formatDateToMMDDYYYY(self.state.voteAssignedDate), true, 'MM/DD/YYYY')) {
        self.props.change('voteDate', value);
        self.setState({ voteAssignedDate: value })
    }
}
export const handleChangeClassificationDate = (event, value) => {
    self.setState({ classificationDate: utilCommon.formatDateToMMDDYYYY(value) })
}
export const handleChangeVoteAssignedDate = (event, value) => {
    self.setState({ voteAssignedDate: utilCommon.formatDateToMMDDYYYY(value) })
}
export const cancelAdministrativeAssistantOrStaffManagerPopup = () => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
}

export const sortCommonOfficerTitles = (list) => {
    let sortedList = [];
    if (list && list.length > 0) {
        sortedList = list.sort((a, b) => {
            if (a.text.toLowerCase() < b.text.toLowerCase())
                return -1;
            if (a.text.toLowerCase() > b.text.toLowerCase())
                return 1;
            return 0;
        })
        sortedList.unshift({ 'key': 0, 'text': 'Select', 'value': 0 });
    }
    return sortedList;
}

const resetPrimaryActivitydata = () => {
    self.props.change('natureOfInterest', "")
    self.props.change('describeProducts', "")
}

export const getPrimaryActivityList = (customMembershipTypeId) => {
    self.props.getPrimaryActivityList(customMembershipTypeId, (response) => {
        const primaryActivityDropdown = DropdownFormatting(response, 'primaryActivityDropdown');
        self.setState({ masterPrimaryActivityDropdown: primaryActivityDropdown }, () => {
            filterPrimaryActivitiesOnOrgAssociation(self.state.isOrgAssociated);
            self.props.change('primaryActivityOfMember', self.state.primaryActivityOfMember);
        });
    });
}

export const onChangeCommitteeDetails = (event, value, preValue, name) => {
    if (name !== 'subCommittee') {
        self.setState({ [name]: value }, () => {
            if (value === -2) {
                self.setState({ subCommittee: [] }, () => {
                    self.props.change('subCommittee', self.state.subCommittee);
                });
            };
        });
    }
    if (name === 'primaryActivityOfMember') {
        self.setState({ [name]: value }, () => {
            self.props.change('primaryActivityOfMember', value);
        })
    }

    if (name === 'mainCommittee') {
        if (value > 0) {
            const committeeId = value;
            self.setState({ subCommittee: [] }, () => {
                self.props.change('subCommittee', self.state.subCommittee);
            });
            self.props.getSubCommitteesList(committeeId, (response) => {
                const subCommitteeDropdown = DropdownFormatting(response, 'subCommitteeDropdown');
                self.setState({ subCommitteeDropdown });
            });
        }
    } else if (name === 'subCommittee') {
        const { subCommitteeDropdown } = self.state;
        let selectedCommittees = [];
        let previousSelectedCommittees = [];
        let itemAdded = [];
        selectedCommittees = value;
        previousSelectedCommittees = self.state.subCommittee;

        let removedCommitteeId = previousSelectedCommittees.filter((item) => !selectedCommittees.some((item2) => item2 == item));
        if (removedCommitteeId && removedCommitteeId.length > 0) {
            removedCommitteeId.map(((removedCommitteeIdItem) => {
                const removedCommitteeObj = find(subCommitteeDropdown, { SubCommitteeId: removedCommitteeIdItem });
                const filteredCommitteeIdArray = [];
                forEach(selectedCommittees, (subId) => {
                    const subCommitteeObj = find(subCommitteeDropdown, { SubCommitteeId: subId });
                    if (subCommitteeObj.CommitteeDesignation.indexOf(removedCommitteeObj.CommitteeDesignation) !== 0) {
                        filteredCommitteeIdArray.push(subCommitteeObj.SubCommitteeId);
                    }
                });

                self.setState({ subCommittee: filteredCommitteeIdArray }, () => {
                    self.props.change('subCommittee', filteredCommitteeIdArray);
                });
            }));

        } else {
            forEach(selectedCommittees, (subId) => {
                const subCommittee = find(subCommitteeDropdown, { SubCommitteeId: subId });
                const { CommitteeDesignation } = subCommittee;
                let splitDesgination = CommitteeDesignation.split('.');
                let checkedCommittee = splitDesgination[0]; // main committee
                for (let i = 1; i < splitDesgination.length; i++) {
                    checkedCommittee += "." + splitDesgination[i];
                    let autoSelectedCommittee = find(subCommitteeDropdown, { CommitteeDesignation: checkedCommittee });
                    if (!isEmpty(autoSelectedCommittee)) {
                        itemAdded.push(autoSelectedCommittee.SubCommitteeId);
                    }
                }
            });
            itemAdded = uniq(itemAdded);
            self.setState({ subCommittee: itemAdded }, () => {
                self.props.change('subCommittee', itemAdded);
            });
        }

    }
}


export const filterPrimaryActivitiesOnOrgAssociation = (isOrgAssociated) => {
    self.setState({ primaryActivityOfMember: -1 }, () => {
        self.props.change('primaryActivityOfMember', -1);
        resetPrimaryActivitydata();
    });
    let primaryActivityDropdown = []
    if (isOrgAssociated) {
        primaryActivityDropdown = self.state.masterPrimaryActivityDropdown
    } else {
        primaryActivityDropdown = self.state.masterPrimaryActivityDropdown.filter(x => (x.CommitteePrimaryActivityId === primaryActivityModelConstant.consumer || x.CommitteePrimaryActivityId === primaryActivityModelConstant.other || x.CommitteePrimaryActivityId === primaryActivityModelConstant.consultant || x.key === -1))
    }
    self.setState({ primaryActivityDropdown })
}

export const onChangeCommonForm = (event, value, preValue, name) => {
    self.setState({ [name]: value });
};

// Prepare Committee Data
export const committeeAndClassificationDetails = (postData) => {
    const sameActivityIds = [
        primaryActivityModelConstant.manufacturerOfProductService,
        primaryActivityModelConstant.salesDistributorOfMaterialProductService,
        primaryActivityModelConstant.userPurchaserOfProductService,
        primaryActivityModelConstant.testingOfProductService
    ];
    let committeememberclassification = {};

    const primaryActivityOfMember = postData.primaryActivityOfMember;

    if (primaryActivityOfMember > 0) {
        if (sameActivityIds.indexOf(primaryActivityOfMember) >= 0) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.describeProducts ? postData.describeProducts : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.consultant || primaryActivityOfMember === primaryActivityModelConstant.consultingFirm) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                multipleorganization: postData.bestDescribesConsultingFirm === "2",
                singleorganization: postData.bestDescribesConsultingFirm === "1",
                nameoforganization: postData.bestDescribesConsultingFirm === "2" ? postData.organizationNameYouRepresenting : postData.organizationNameYouRepresent,
                website: postData.organizationWebsiteConsultantDetails ? postData.organizationWebsiteConsultantDetails : null,
                primarilyconsultant: postData.organizationPrimarilyProduceProducts === "2",
                businessactivity: postData.bestDescribesConsultingFirm === "1"
                    ? (postData.organizationPrimarilyProduceProducts === "2" ? postData.productSells : postData.businessActivity)
                    : (postData.anyOrganizationPrimarilyProduceProducts === "2" ? postData.productSells : postData.businessActivity)
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.consumer || primaryActivityOfMember == primaryActivityModelConstant.consumerAdvocacyGroup) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                facilityorganization: postData.consumerAdvocacyGroupName ? postData.consumerAdvocacyGroupName : null,
                advocacyemail: postData.advocacyGroupEmail ? postData.advocacyGroupEmail : null,
                organizationdescription: postData.natureOfInterestConsumerDetails ? postData.natureOfInterestConsumerDetails : postData.natureOfInterest ? postData.natureOfInterest : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.governmentAgency) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.natureOfInterest ? postData.natureOfInterest : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.academia) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.natureOfInterest ? postData.natureOfInterest : null
            };
        } else if (primaryActivityOfMember === primaryActivityModelConstant.other) {
            committeememberclassification = {
                primaryactivityid: primaryActivityOfMember,
                organizationdescription: postData.natureOfInterest ? postData.natureOfInterest : null
            };
        }
        const committeedetails = {
            CommitteeIds: postData.CommitteeIds,
            subcommitteeid: isEmpty(postData.subCommitteeIds) ? [] : postData.subCommitteeIds,
            committeememberclassification
        };
        return committeedetails;
    }

    return null;
};
